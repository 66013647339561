import { Alert } from "@datamole/wds-component-alert";
import { ReactNode } from "react";
import styled from "styled-components";

import { LoginErrorType } from "pages/LoginPage/login-page.types";

const errorData: Record<LoginErrorType, { title: string; subtitle?: string; content: ReactNode }> = {
    invalidLogin: {
        title: "Your user name or password is invalid.",
        subtitle: "Please try again.",
        content: (
            <>
                <br />
                <div>
                    You need to have an <strong>LSSA account</strong> to log in → request your Lely Cluster for that.
                </div>
                <br />
                <div>If you already have an LSSA account and are still unable to log in, contact us at </div>
                <div>
                    <a href="mailto:lssa-support@datamole.atlassian.net">lssa-support@datamole.atlassian.net</a>
                </div>
            </>
        ),
    },
    tooManyLoginAttempts: {
        title: "Too many login attempts.",
        subtitle: "Please try again later.",
        content: (
            <>
                <br />
                <div>
                    You need to have an <strong>LSSA account</strong> to log in → request your Lely Cluster for that.
                </div>
                <br />
                <div>If you already have an LSSA account and are still unable to log in, contact us at </div>
                <div>
                    <a href="mailto:lssa-support@datamole.atlassian.net">lssa-support@datamole.atlassian.net</a>
                </div>
            </>
        ),
    },
    expiredPassword: {
        title: "Your password has expired.",
        content: (
            <>
                <br />
                <div>Reset your LelyNet password → contact Lely IT for support at </div>
                <div>
                    <a href="mailto:it-servicedesk@lely.com">it-servicedesk@lely.com</a>
                </div>
            </>
        ),
    },
    serverError: {
        title: "Internal server error.",
        subtitle: "Please try again.",
        content: (
            <>
                <br />
                <div>If the issues persist, contact us at </div>
                <div>
                    <a href="mailto:lssa-support@datamole.atlassian.net">lssa-support@datamole.atlassian.net</a>
                </div>
            </>
        ),
    },
};

const AlertStyled = styled(Alert)`
    margin-bottom: 1rem;
`;

type Props = {
    type: LoginErrorType;
};

const LoginError = (props: Props) => {
    const data = errorData[props.type];

    return (
        <AlertStyled severity={"warning"}>
            <Alert.Content>
                <Alert.Title>{data.title}</Alert.Title>
                {data.subtitle && <Alert.Title>{data.subtitle}</Alert.Title>}
                {data.content}
            </Alert.Content>
        </AlertStyled>
    );
};

export { LoginError };
